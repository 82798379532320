<template>
	<div>
		 <a-result status="404" title="404" sub-title="很抱歉, 您访问的页面丢了.">
		    <template #extra>
			  <router-link to="/mendian">
		      <a-button type="primary">
		        返回
		      </a-button>
			  </router-link>
		    </template>
		  </a-result>
	</div>
</template>

<script>
	export default{
		data(){
			return {
				
			}
		}
	}
</script>

<style>
</style>